import styled from 'styled-components';

export const Details = styled.div`
  div {
    font-size: 0.8em;

    span {
      font-size: 0.8em;
    }
  }
`;
